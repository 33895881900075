@import "./variables";
.active-link {
  color: $theme-color !important;
}
.background-color {
  background-color: $theme-color !important;
}
.background-white-color {
  background-color: $frequent-color !important;
}
.background-black-color {
  background-color: $back-arrow-color !important;
}
.black-color {
  color: $back-arrow-color !important;
}
.font-color {
  color: $font-color !important;
}
.white-color {
  color: $frequent-color !important;
}
.scream-icon-color {
  color: $scream-icon-color !important;
}
.landing-bg-color {
  background-color: $landing-bg-color !important;
}
.orange-color {
  background-color: $orange-color !important;
}
.green-color {
  background-color: $success-color !important;
}
.grey-disable-color {
  color: $grey-disable-color !important;
}
.red-color {
  color: $red-color !important;
}
.grey-color {
  color: $grey-color !important;
}
.hover-color {
  background-color: $hover-color !important;
}
.blue-color {
  color: $theme-color !important;
}
.profile-color {
  background-color: $profile-color !important;
}
.closed-status-color {
  color: $success-color;
}
.background-light-green-color {
  background-color: $step-icon-color;
}

.verification-color-blue {
  color: $theme-color !important;
}

.verification-color-silver {
  color: #97a5b4 !important;
}

.verification-color-bronze {
  color: #8f531c !important;
}

.verification-color-gold {
  color: #dfb342 !important;
}

.gold-color {
  color: $gold-rated !important;
}

.text-red-color {
  color: $text-red-color;
}

.background-green-color {
  background-color: #e9faeb !important;
}

.background-red-color {
  background-color: #faebe9 !important;
}

.error-color {
  color: $error-color;
}

.background-pale-color {
  background-color: #e8e8e9;
}

.fintcolor {
  color: #757272;
}

.dark-gray-color {
  color: $dark-gray !important;
}
