@import "./variables";

.fw-700 {
  font-weight: $fw-700 !important ;
}
.fw-600 {
  font-weight: $fw-600 !important;
}
.fw-500 {
  font-weight: $fw-500 !important;
}
.fw-400 {
  font-weight: $fw-400 !important;
}
.fw-800 {
  font-weight: $fw-800 !important;
}
