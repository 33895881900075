/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "./scss/variables";
@import "./scss/colors.scss";
@import "./scss/font-sizes.scss";
@import "./scss/font-weights.scss";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

@font-face {
  font-family: "Scremerfont";
  src: url("../src/assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}
html,
body {
  height: 100%;
  margin: 0;
  font-family: "Scremerfont", $theme-font-family !important;
  color: #1b1d1f !important;
  letter-spacing: normal;
}

h1,
h2,
h3,
h4,
h5,
p,
a {
  font-family: "Scremerfont", $theme-font-family !important;
}

.btn-primary {
  border-radius: $btn-border-radius !important;
  border-style: none !important;
  justify-content: center !important;
  height: 40px !important;
}

.pointer {
  cursor: pointer;
}

.object-fill {
  object-fit: fill !important;
}
.object-contain {
  object-fit: contain !important;
}
.object-cover {
  object-fit: cover !important;
}
.rounded-object {
  border-radius: 50% !important;
}
.square-object {
  border-radius: 5px;
  // background-color: #E8E8E9 ;
}
.profile-image-40 {
  width: 40px !important;
  height: 40px !important;
}
.profile-image-88 {
  width: 88px !important;
  height: 88px !important;
}
.profile-image-80 {
  width: 80px !important;
  height: 80px !important;
}
.profile-image-32 {
  width: 32px !important;
  height: 32px !important;
}
.carousel-indicators {
  display: none !important;
}
.icon-height {
  position: relative;
  top: 2px !important;
}
.myTooltip {
  .mdc-tooltip__surface {
    max-width: 300px;
    margin-bottom: 15px;
  }
}

.mat-tab-label-content,
.mat-card-title,
.mat-card-subtitle {
  font-family: "Scremerfont", $theme-font-family !important;
}

.mdc-text-field--filled {
  background-color: $invisible-color !important;
  background: $invisible-color !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: $invisible-color !important;
  background: $invisible-color !important;
}

.auto-width {
  width: $width !important;
}
.name-wrap {
  word-break: break-word !important;
}

@media (min-width: 768px) {
  .display-mobile-only {
    display: none !important;
  }

  .mb {
    margin-bottom: 30px !important;
  }

  .pb {
    padding-bottom: 30px !important;
  }
}

@media (min-width: 576px) {
  .display-mobiles-only {
    display: none !important;
  }
  .pt-7 {
    padding-top: 56px !important;
  }
}

@media (max-width: 576px) {
  .display-desktops-only {
    display: none !important;
  }
  .width {
    width: 100% !important;
  }
  .box-shadow {
    box-shadow: none !important;
  }
  .pt-sm-8 {
    padding-top: 3.5rem !important;
  }
  .mt-xsm-3 {
    margin-top: 16px;
  }
  .padding-top-56 {
    padding-top: 56px !important;
  }
  .header-padding {
    padding: 0 16px !important;
  }
}

@media (max-width: 767px) {
  .icons-flex {
    flex-direction: column;
  }
  .display-desktop-only {
    display: none !important;
  }
  .icon-container {
    text-align: start;
  }
  .mb-6 {
    margin-bottom: 80px !important;
  }

  .pb-6 {
    padding-bottom: 80px !important;
  }

  .mat-mdc-snack-bar-container {
    padding-bottom: 100px !important;
  }
}

@media (min-width: 576px) {
  .pt-7 {
    padding-top: 52px !important;
  }

  .pt-8 {
    padding-top: 100px !important;
  }

  .vertical-line {
    border-right: 1px solid $imagepreview-outline-color;
    border-left: 1px solid $imagepreview-outline-color;
    min-height: 100vh;
  }

  .disable-right-vertical-line {
    border-right: 0px solid $imagepreview-outline-color;
  }
  .container-sm {
    max-width: 540px !important;
  }
}

.txt-secondary-col {
  color: $scream-icon-color;
}

.mt-6 {
  margin-top: 4rem;
}

.img_border {
  border: 1px solid $imagepreview-outline-color;
  border-radius: 16px !important;
}

.mat-mdc-floating-label {
  font-size: $fs-14 !important;
  font-family: "Scremerfont", $theme-font-family !important;
}

.mat-mdc-option .mdc-list-item__primary-text {
  font-size: $fs-16 !important;
  font-family: "Scremerfont", $theme-font-family !important;
}

.mat-mdc-select-value {
  font-size: $fs-16 !important;
  font-family: "Scremerfont", $theme-font-family !important;
}

.success {
  color: $success-color;
  font-size: $fs-12;
}

.error {
  color: $error-color;
  font-size: $fs-12;
}

.lato-font-family {
  font-family: "Scremerfont", $theme-font-family !important;
}

.mat-mdc-snack-bar-container {
  &.success-snackbar {
    --mdc-snackbar-container-color: #2b60af;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
    margin: 8px;
    text-align: center;
  }

  &.error-snackbar {
    --mdc-snackbar-container-color: #de0202;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
    margin: 8px;
    text-align: center;
  }
  &.view-snackbar {
    --mdc-snackbar-container-color: #2b60af;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
    --mat-snack-bar-button-color: #fff;
  }
}
.about {
  text-align: justify;
}
.dotted-line {
  height: 100%;
  border-left: 1px dashed #919192;
}
.mt-32 {
  margin-top: 32px !important;
}
.mat-mdc-snack-bar-container .mdc-snackbar__label {
  font-family: $theme-font-family !important;
  font-size: $fs-14 !important;
}

.border-radius {
  border-radius: 10px !important;
}

.btn {
  border-radius: 20px !important;
  border: 1px solid $theme-color !important;
  box-shadow: none !important;
  padding: 0 24px !important;
}

.header-size {
  height: 56px !important;
  top: 0;
  position: sticky;
  z-index: 999 !important;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
}

.disabled {
  opacity: 0.5;
}

.invisible-scrollbar::-webkit-scrollbar {
  display: none;
}

.highlighted {
  color: $emotion-color !important;
  font-weight: 600 !important;
  text-decoration: none;
  cursor: pointer !important;
}

.highlighted:hover {
  text-decoration: underline;
}

.mat-icon-height {
  height: 24px !important;
  width: 24px !important;
}

.fixed {
  position: fixed;
  top: 0;
}
.wrap-it {
  word-wrap: break-word;
  text-align: justify;
}
.disabled-button {
  opacity: 0.5;
  cursor: not-allowed;
}
.button[disabled]:hover {
  opacity: 0.5;
  cursor: not-allowed;
}
.profile-border {
  border: 1px solid #e8e8e9;
}
.follow-btn {
  border-radius: 20px !important;
  border: 1px solid $theme-color !important;
  box-shadow: none !important;
  padding: 0 18px !important;
}

@media (min-width: 1400px) {
  .max-width {
    width: 1140px !important;
  }
}

@media (min-width: 1400px) {
  .max-width-header {
    width: 1320px !important;
  }
}

.cdk-global-scrollblock {
  position: inherit !important;
  overflow-y: hidden !important;
}
.high-lighted:hover {
  text-decoration: underline;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}

@media only screen and (max-width: 576px) {
  .custom {
    display: inline-block !important;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}
.google {
  border-radius: 100px;
  border: 1px solid var(--primary-primary-500, #2b60af);
  background: #fff;
}

.cancel-plan-dialog .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 20px !important;
}

.event-box .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 20px !important;
}

.event-box .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 20px !important;
}

.profile-image-80 {
  width: 80px !important;
  height: 80px !important;
}

.square-object {
  border-radius: 5px;
}
